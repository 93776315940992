






























































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import Pricing from "@/components/order_form/Pricing.vue";
import Share from "@/components/share/Share.vue";
import ConfigFileService from "@/services/config_file_service";
import { ConfigCreator } from "@/mixins/configurator";
import {
  APIError,
  AuthError,
  BadRequest,
  GenericError,
} from "@/services/error_service";
import Download from "@/components/download/Download.vue";
import { EventBus } from "@/event-bus";
import { Auth } from "@/mixins/auth";
import { Roles } from "@/models/user/roles";
import QuickShip from "@/views/QuickShip.vue";
import UIkit from "uikit";
import { ConfigType } from "@/models/configurator/config_file";
import { Preset } from "@/models/configurator/configurator_options";
import LeadTime from "@/components/order_form/LeadTime.vue";
import AuthService from "@/services/auth_service";
import { StockMixin } from "@/mixins/stock";
import ProductService from "@/services/product_service";
import {
  BaseStore,
  CanopyStore,
  FrameStore,
  LayoutStore,
  ShapeStore,
} from "@/mixins/store";
@Component({
  components: {
    Pricing,
    Share,
    Download,
    QuickShip,
    LeadTime,
  },
})
export default class Footer extends mixins(
  LayoutStore,
  ShapeStore,
  CanopyStore,
  BaseStore,
  FrameStore,
  ConfigCreator,
  Download,
  Auth,
  StockMixin
) {
  protected configFileService = new ConfigFileService();
  protected shareLink = "";
  protected showShareDialog = false;
  protected loader: any = null;
  protected Roles = Roles;
  protected Preset = Preset;
  protected disable = false;
  protected authService = new AuthService();
  protected sku = "";
  protected shortSku = "";
  protected hasLongSku = false;
  protected productService = new ProductService();

  /** Locking down footer functions as the models change, as this can cause bugs */
  created(): void {
    EventBus.$on("startModelChange", () => {
      this.disable = true;
      this.$viewer.addEventListener("model.loaded", (event) => {
        this.disable = false;
      });
    });
  }

  protected closeShare(): void {
    this.showShareDialog = false;
  }

  public async logout(): Promise<void> {
    try {
      this.setLoggedIn(false);
      this.setUserRole(null);
      await this.authService.logout();
      this.$router.push("/");
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        GenericError.popup(err.message);
      }
    }
  }

  protected updateSKU(newSkuValue: string) {
    this.sku = newSkuValue;
    const charLimit = 40;
    if (newSkuValue.length > charLimit) {
      const skuSubstr = newSkuValue.substring(0,charLimit);
      const pipeBarIdx = skuSubstr.lastIndexOf("|");
      this.shortSku = pipeBarIdx < charLimit ? skuSubstr.substring(0,pipeBarIdx) : skuSubstr;
      this.hasLongSku = true;
    } else {
      this.shortSku = newSkuValue;
      this.hasLongSku = false;
    }
  }

  protected async getImagesShare(): Promise<void> {
    this.showShareDialog = false;
    this.loader = this.$loading.show(
      { opacity: 1 },
      { before: this.$createElement("h1", "Generating share link...") }
    );
    EventBus.$emit("getStaticImages", this.share);
  }

  protected async getImagesQuickShip(): Promise<void> {
    this.loader = this.$loading.show(
      { opacity: 1 },
      { before: this.$createElement("h1", "Generating quick ship record...") }
    );
    EventBus.$emit("getStaticImages", this.makeQuickShip);
  }

  protected async getImagesQuote(): Promise<void> {
    this.loader = this.$loading.show({ opacity: 1 });
    EventBus.$emit("getStaticImages", this.getQuote);
  }

  protected getQuote(images: string[]): void {
    this.loader.hide();
    this.$router.push({
      name: "RequestQuote",
      params: {
        img1: images[0],
        img2: images[1],
        img3: images[2],
      },
    });
  }

  protected async makeQuickShip(images: string[]): Promise<void> {
    try {
      const data = await this.transformDataToConfigFile();
      const body = {
        ...data,
        type: ConfigType.QS,
        image: images[0],
        stock: this.isStockOnly,
      };
      const res = await this.configFileService.createFile(body);
      this.shareLink = process.env.VUE_APP_SHARE_LINK + "/view?design=" + res._id;
      this.loader.hide();
      UIkit.notification({
        message: `QuickShip file created, link: ${this.shareLink}`,
      });
    } catch (err) {
      if (err instanceof BadRequest) {
        BadRequest.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
      setTimeout(() => {
        this.loader.hide();
      }, 1000);
    }
  }

  protected async share(images: string[]): Promise<void> {
    try {
      const data = await this.transformDataToConfigFile();
      const body = { ...data, image: images[1], stock: this.isStockOnly };
      const res = await this.configFileService.createShareFile(body);
      this.shareLink =
        process.env.VUE_APP_SHARE_LINK + "/view?design=" + res.share_id;
      setTimeout(() => {
        this.loader.hide();
        this.showShareDialog = true;
      }, 1000);
    } catch (err) {
      if (err instanceof BadRequest) {
        BadRequest.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
      setTimeout(() => {
        this.loader.hide();
      }, 1000);
    }
  }

  protected formatTitle(title: string, part: string): string {
    let result = "";
    if (title.indexOf(" ")) {
      if (part == "a") {
        result = title.substr(0, title.indexOf(" "));
      } else {
        result = title.substr(title.indexOf(" ") + 1);
      }
    } else {
      return title;
    }

    return result;
  }

  protected stepForward(): void {
    switch (this.$route.name) {
      case "Layout":
        this.$router.push("/designer/canopy");
        break;
      case "Shape":
        this.$router.push("/designer/frame");
        break;
      case "Canopy":
        this.$router.push("/designer/base");
        break;
      case "Frame":
        this.$router.push("/designer/layout");
        break;
      case "Base":
        this.$router.push("/designer");
        break;
    }
  }

  protected stepBack(): void {
    switch (this.$route.name) {
      case "Layout":
        this.$router.push("/designer/frame");
        break;
      case "Shape":
        this.$router.push("/designer/base");
        break;
      case "Canopy":
        this.$router.push("/designer/layout");
        break;
      case "Frame":
        this.$router.push("/designer");
        break;
      case "Base":
        this.$router.push("/designer/canopy");
        break;
    }
  }
  get prevLabel(): string {
    let label = "";
    switch (this.$route.name) {
      case "Layout":
        label = "Frame";
        break;
      case "Shape":
        label = "Base";
        break;
      case "Canopy":
        label = "Layout";
        break;
      case "Frame":
        label = "Shape & Size";
        break;
      case "Base":
        label = "Canopy";
        break;
    }
    return label;
  }

  get nextLabel(): string {
    let label = "";
    switch (this.$route.name) {
      case "Layout":
        label = "Canopy";
        break;
      case "Shape":
        label = "Frame";
        break;
      case "Canopy":
        label = "Base";
        break;
      case "Frame":
        label = "Layout";
        break;
      case "Base":
        label = "Shape & Size";
        break;
    }
    return label;
  }
}
